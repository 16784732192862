import Navbar from '../landing/Navbar'
import Hero from '../landing/Hero'
import { Footer } from '../landing/Footer'
import Banner from '../layout/Banner'

const timeline = [
  {
    name: 'Early stage pilots',
    description: "We saw a gap in the market and we decided to test our hypothesis of voice assistants playing a wide variety of roles in everyday life",
    date: 'Summer 2022',
    dateTime: '2022-06',
  },
  {
    name: 'Founded company',
    description: 'Our initial testing was a huge success in Senior Living & Hospitality. We founded the company to execute on our vision.',
    date: 'Mar 2023',
    dateTime: '2023-03',
  },
  {
    name: 'Secured funding from US Bank',
    description: 'Our work was being noticed globally. We secured funding from Elavon, a subsidiary of US Bank to accelerate our journey.',
    date: 'June 2023',
    dateTime: '2023-05',
  },
  {
    name: 'Help at Home',
    description: "Following the success of our Hospitality & Senior Living products, we're launching our product for the at Home market.",
    date: 'Q4 2023',
    dateTime: "2023-09",
  },
]
const stats = [
  { label: 'Requests handled', value: '> 30,000' },
  { label: 'The age of our oldest user', value: "104 years" },
  { label: "Countries we service", value: '8 +' },
]
const values = [
  {
    name: 'Strive for change',
    description: "We started on this mission to make a change to people's lives. We embody this desire for change with every action we take but only if it has purpose.",
  },
  {
    name: 'Take risk',
    description: "We're not afraid of a challenge and we know that the greatest rewards come from the biggest risks. Nothing significant in life has been achieved by playing it safe and we encourage our team to step outside their comfort zone if they genuinely believe in something.",
  },
  {
    name: 'Innovation',
    description: "We take a collaborative approach to everything we do and our roadmap is focused on innovative change with purpose not simply profit.",
  },
  {
    name: 'Be supportive',
    description: "We believe that diversity offers a wealth of knowledge through different experiences. We regularly find ourselves learning from one sector and taking those learnings to develop improvements in another.",
  },
  {
    name: "Be mindful",
    description: "Being mindful is really important to us. We love what we do but our teams' happiness is essential to a successful business. If you don't enjoy what you do you're less likely to create something people will enjoy using.",
  },
  {
    name: 'Tech for Good',
    description: "One notion unites all our founding members and newcomers - to use technology for the good of society. Making something better means more to us than making something for the sake of it.",
  },
]
const team = [
  {
    name: 'George',
    role: 'Co-Founder / CEO',
    imageUrl: '/img/graphics/team/george.svg',
  },
  {
    name: 'Arun',
    role: 'Co-Founder / CTO',
    imageUrl:
      '/img/graphics/team/arun.svg',
  },
  // {
  //   name: 'Andy',
  //   role: 'CFO',
  //   imageUrl:
  //     '/img/graphics/team/andy.svg',
  // },
  // {
  //   name: 'Andrew',
  //   role: 'COO',
  //   imageUrl:
  //     '/img/graphics/team/andrew.svg',
  // },
  // {
  //   name: 'Jeff',
  //   role: 'Advisor',
  //   imageUrl:
  //     '/img/graphics/team/jeff.svg',
  // },
  {
    name: 'Annabelle',
    role: 'Head of Marketing',
    imageUrl:
      '/img/graphics/team/annabelle.svg',
  },
  {
    name: 'Joe',
    role: 'Head of Sales',
    imageUrl:
      '/img/graphics/team/joe.svg',
  },
  {
    name: 'Priya',
    role: 'AI Engineer',
    imageUrl: '/img/graphics/team/priya.svg',
  },
  {
    name: 'Varsha',
    role: 'AI Engineer',
    imageUrl:
      '/img/graphics/team/varsha.svg',
  },
  {
    name: 'Ilana',
    role: 'Data Analyst',
    imageUrl:
      '/img/graphics/team/ilana.svg',
  },
  {
    name: 'Olivier',
    role: 'Software Engineer',
    imageUrl:
      '/img/graphics/team/olivier.svg'
  },
]

// const blogPosts = [
//   {
//     id: 1,
//     title: 'Vel expedita assumenda placeat aut nisi optio voluptates quas',
//     href: '#',
//     description:
//       'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//     date: 'Mar 16, 2020',
//     datetime: '2020-03-16',
//     author: {
//       name: 'Michael Foster',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   // More posts...
// ]

export default function About() {

  return (
    <>
      <Navbar />

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                We're an innovative, cross-sector team, that believes in technology with purpose.
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  Innovation is at the core of our business and we are always looking at new ways to break boundaries and improve lives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      <div className="bg-white">
        <main className="isolate">

          {/* Timeline section */}
          <div className="mx-auto my-36 mt-12 sm:my-44 sm:mt-24 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {timeline.map((item) => (
                <div key={item.name}>
                  <time
                    dateTime={item.dateTime}
                    className="flex items-center text-sm font-semibold leading-6 text-info"
                  >
                    <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                      <circle cx={2} cy={2} r={2} fill="currentColor" />
                    </svg>
                    {item.date}
                    <div
                      className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                      aria-hidden="true"
                    />
                  </time>
                  <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
                  <p className="mt-1 text-base leading-7 text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Mission */}
          <div className="mx-auto py-12 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h2>
              <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                  <p className="text-xl leading-8 text-gray-600">
                    To enable anyone, in any place, in any language, with any accessibility needs to feel empowered through voice and multi-modal technologies.
                  </p>
                  <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                    <p>
                      Whether it's listening to your favourite story, connecting with a loved one, ordering food to your room or making a payment, we believe it's our job to create the simplest, most accessible solutions possible.
                    </p>
                    <p className="mt-10">
                      Everything about the way we design, work and build our technology is centred around enhancing real-world, physical experiences. If we can't make something better, we won't do it.
                    </p>
                  </div>
                </div>
                <div className="lg:flex lg:flex-auto lg:justify-center">
                  <dl className="w-64 space-y-8 xl:w-80">
                    {stats.map((stat) => (
                      <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                        <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {/* Image section */}
          <div className="my-12 sm:my-20 xl:mx-auto xl:max-w-7xl xl:px-8">
            <img
              src="/img/graphics/stock/horizon.png"
              alt=""
              className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
            />
          </div>

          {/* Values section */}
          <div className="mx-auto py-32 sm:py-48 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our values</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We're a forward thinking company that strives to take a modern approach across all aspects of our business. We're always looking for ways to improve and we're not afraid to take risks.
              </p>
            </div>
            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {values.map((value) => (
                <div key={value.name}>
                  <dt className="font-semibold text-gray-900">{value.name}</dt>
                  <dd className="mt-1 text-gray-600">{value.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          {/* Team section */}
          <div className="mx-auto pt-16 sm:pt-20 pb-32 sm:pb-48 max-w-7xl px-6 sg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our team</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Meet the team behind the vision, technology & brand that is audico. We're a team of passionate individuals that is growing fast. We're always looking for new talent to join us on our journey.
              </p>
            </div>
            <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6">
              {team.map((person) => (
                <li key={person.name}>
                  {/* <img className="mx-auto h-24 w-24 rounded-full" src={person.imageUrl} alt="" /> */}
                  <img className="mx-auto rounded-2xl" src={person.imageUrl} alt="" />
                  <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm leading-6 text-gray-600">{person.role}</p>
                </li>
              ))}
            </ul>
          </div>

          {/* Blog section */}
          {/* <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Vel dolorem qui facilis soluta sint aspernatur totam cumque.
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {blogPosts.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                >
                  <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    <time dateTime={post.datetime} className="mr-8">
                      {post.date}
                    </time>
                    <div className="-ml-4 flex items-center gap-x-4">
                      <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="flex gap-x-2.5">
                        <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                        {post.author.name}
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                </article>
              ))}
            </div>
          </div> */}

        </main>

      </div>

      <Banner />
      <Footer />
    </>
  )
}
